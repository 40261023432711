import { createStore, StoreEnhancer } from 'redux';
import { rootReducer } from './RootReducer';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?(): StoreEnhancer;
  }
}

const enhancer = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
export const store = createStore(
  rootReducer,
  enhancer,
);
