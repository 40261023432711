import { APIError } from '@conventioncatcorp/common-fe/dist/APIClient';
import React, { Component } from 'react';
import Calendar, { OnChangeDateRangeCallback } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Link } from 'react-router-dom';
import { Alert, Badge, Card, CardText, CardTitle, Col, Row } from 'reactstrap';
import { ExpandableText, Language, MaterialIcon, RoomDraw } from '../../components';
import { Config, Hotel, ReleaseSchedule, RoomBlock, User } from '../../models';
import history from '../../services/history';
import { commaFormat, removeHTMLEntities } from '../../utils';

interface RoomContainerState {
  roomTypes: RoomBlock[];
  release?: ReleaseSchedule;
  loaded?: boolean;
  hiddenRoomTypes?: number[];
  showHiddenRooms: boolean;
  globalAdditionalInfo?: string;
  bookingDates?: {
    start: Date;
    end: Date;
  };
  selectedDates?: {
    checkIn: Date;
    checkOut: Date;
  };
}

interface RoomContainerProps {
  hotel?: Hotel;
  config: Config;
  user?: User;
}

export class RoomContainer extends Component<RoomContainerProps, RoomContainerState> {
  public override state: RoomContainerState = {
    roomTypes: [],
    showHiddenRooms: false,
  };

  public override async componentDidMount() {
    await this.findRelease();
  }

  public override async componentWillReceiveProps() {
    if (this.state.loaded) {
      await this.findRelease();
    }
  }

  public override render() {
    const { hotel } = this.props;

    if (!hotel) {
      return (
        <Card
          className='text-center'
          body
        >
          <Language name='invalidHotel' />
        </Card>
      );
    }

    return this.renderHotel(hotel);
  }

  private renderHotel(hotel: Hotel) {
    const {
      globalAdditionalInfo,
      release,
      loaded,
    } = this.state;
    const { config, user } = this.props;
    const { location } = history;
    const hotelAddress = commaFormat([
      hotel.addressLine1,
      hotel.addressLine2,
      hotel.addressCity,
      hotel.addressState,
      hotel.addressZipcode,
    ]);

    return (
      <Row>
        { location.pathname !== '/' && (
          <Col lg={12}>
            <Card body>
              <CardText style={{marginTop: '-7px'}}>
                <Link to='/'>
                  <MaterialIcon name='arrow_back_ios' className='inline' />
                  <Language name='hotelSelectionBackBtn' plain />
                </Link>
              </CardText>
            </Card>
          </Col>
        ) }
        { release && (
          <Col lg={12}>
            <RoomDraw
              config={config}
              release={release}
              user={user}
            />
          </Col>
        )}
        <Col lg={8}>
          <Card body>
            <CardTitle>
              {hotel.name}<br />
              <small>{hotelAddress}</small>
            </CardTitle>
            <CardText className='margin-bottom-0'>
              <ExpandableText text={removeHTMLEntities(hotel.description)} />
            </CardText>
            <CardText className='margin-bottom-0'>
              <a
                target='_blank'
                href={`https://www.google.com/maps/place/${hotelAddress}`}
              >
                <MaterialIcon name='map' className='inline' />
              </a>&nbsp;
              <MaterialIcon name='link' className='inline' />
            </CardText>
            { globalAdditionalInfo && (
              <>
                <hr />
                <Alert
                  className='margin-bottom-0'
                  color='dark'
                >
                  {removeHTMLEntities(globalAdditionalInfo)}
                </Alert>
              </>
            )}
          </Card>
        </Col>
        <Col lg={4}>
          <Card className='text-center' body>
            {loaded && this.renderCalendar(hotel)}
            {!loaded && this.renderLoading()}
          </Card>
        </Col>
        <Col lg={12}>
          {this.showContent(hotel)}
        </Col>
      </Row>
    );
  }

  private showContent({ blockClosed }: Hotel): JSX.Element | JSX.Element[] {
    const { loaded, roomTypes } = this.state;

    if (!loaded) {
      return (
        <Card body>
          <div className='text-center'>
            <Language name='loading' plain />
          </div>
        </Card>
      );
    }

    if (blockClosed) {
      return (
        <Alert
          color='danger'
          className='text-center margin-bottom-0'
        >
          <Language name='hotelNoRooms' plain />
        </Alert>
      );
    }

    if (!roomTypes || roomTypes.length === 0) {
      return (
        <Alert
          color='info'
          className='text-center margin-bottom-0'
        >
          <Language name='hotelNextDrawSoon' plain />
        </Alert>
      );
    }

    return roomTypes.map(roomType => this.renderRoomType(roomType));
  }

  private async findRelease() {
    const { hotel } = this.props;

    if (!hotel) {
      return;
    }

    try {
      const release = await api.getHotelReleaseSchedule(hotel.id);
      this.setState({
        loaded: true,
        release: {
          ...release,
          entryEnd: release.entryEnd && new Date(release.entryEnd),
          entryStart: release.entryStart && new Date(release.entryStart),
          releaseEnd: new Date(release.releaseEnd),
          releaseStart: new Date(release.releaseStart),
        },
        ...await this.getRoomTypes(release),
      });
    } catch (e) {
      if (!(e instanceof APIError)) {
        throw e;
      }

      const { errors: { resource } } = e.apiResponse;

      if (resource) { // No release schedules found
        this.setState({
          bookingDates: undefined,
          globalAdditionalInfo: undefined,
          loaded: true,
          release: undefined,
          roomTypes: [],
        });
        return;
      }

      throw e;
    }
  }

  private renderLoading(): JSX.Element {
    return (
      <>
        <CardTitle>
          Loading...
        </CardTitle>
      </>
    );
  }

  private renderCalendar({ blockClosed }: Hotel): JSX.Element {
    const { bookingDates } = this.state;

    if (blockClosed) {
      return (
        <>
          <CardTitle>
            <Language name='noDatesAvailable' plain />
          </CardTitle>
          <CardText>
            <Language name='hotelNoRooms' plain />
          </CardText>
        </>
      );
    }

    if (!bookingDates || !bookingDates.end || !bookingDates.start) {
      return (
        <>
          <CardTitle>
            <Language name='noDatesAvailable' plain />
          </CardTitle>
          <CardText>
            <Language name='checkBackLater' plain />
          </CardText>
        </>
      );
    }

    const endDate = (
      bookingDates && new Date(bookingDates.end)
    ) || new Date();
    endDate.setDate(endDate.getDate() + 1);

    return (
      <>
        <h6>
          <Language name='calendarHeader' plain />
        </h6>
        <Calendar
          selectRange
          calendarType={'US'}
          showNavigation={bookingDates.start.getMonth() !== endDate.getMonth()}
          showNeighboringMonth={false}
          onChange={(dates => this.onDateSelected(dates as [Date, Date])) as OnChangeDateRangeCallback}
          activeStartDate={bookingDates.start}
          minDate={bookingDates.start}
          maxDate={endDate}
        />
      </>
    );
  }

  private async getRoomTypes({ roomBlocks }: ReleaseSchedule) {
    let globalAdditionalInfo: string | undefined;

    if (roomBlocks) {
      const additionalInfoArr = roomBlocks
        .map(({ additionalInfo }) => additionalInfo);
      if (additionalInfoArr.every((val, _, arr) => val === arr[0])) {
        globalAdditionalInfo = additionalInfoArr[0];
      }
    }

    return {
      bookingDates: this.findBookingDates(roomBlocks || []),
      globalAdditionalInfo,
      roomTypes: roomBlocks,
    };
  }

  private onDateSelected(dates: [Date, Date]) {
    if (Array.isArray(dates)) {
      this.setState({
        ...this.state,
        selectedDates: {
          checkIn: dates[0],
          checkOut: dates[1],
        },
      });
    }
  }

  private findBookingDates(blocks: RoomBlock[]) {
    if (!blocks) {
      return;
    }

    const dates = blocks.reduce((acc, { availability }) => {
      return [...acc, ...availability.map(({ availableAt }) => new Date(availableAt))];
    }, [] as Date[]);

    const sorted = dates.sort((a, b) => a.getTime() - b.getTime());
    const first = sorted.shift()!;

    return {
      end: sorted.pop() || first,
      start: first,
    };
  }

  private renderRoomType(roomType: RoomBlock) {
    const { globalAdditionalInfo, selectedDates } = this.state;
    const roomInfo = this.calculateRoomCosts(roomType);
    const isSoldOut = roomInfo.nights <= 0;
    return (
      <Card className='margin-bottom-10' body key={roomType.id}>
        <div className='margin-bottom-0 room-type card-text'>
          {
            selectedDates ? (
              <h5 className='pull-right'>
                {
                  isSoldOut ? (
                    <>
                      <Badge color='danger'>Sold Out</Badge>
                    </>
                  ) : (
                    <>
                      ${roomInfo.average!} <small>avg / night</small><br />
                      <small>(inc taxes &amp; fees)</small>
                    </>
                  )
                }
              </h5>
            ) : (
              <h5 className='pull-right'>
                <small>Select Check In / Check Out Dates</small>
              </h5>
            )
          }
          <h5>
            <MaterialIcon name='chevron_right' className='inline' /> {roomType.name}
          </h5>
          <p className='icons margin-bottom-0'>
            <MaterialIcon name='person' className='inline' /> 1 - {roomType.maxPersons} People &emsp;
            <MaterialIcon name='info' className='inline' /> <a href='#'>More Info</a>
          </p>
          { roomType.description && (
            <p className='margin-bottom-0 margin-top-5'>
              <ExpandableText text={removeHTMLEntities(roomType.description)} />
            </p>
          )}
          { (roomType.additionalInfo && !globalAdditionalInfo) && (
            <>
              <br />
              <Alert
                color='dark'
              >
                {roomType.additionalInfo}
              </Alert>
            </>
          ) }
          {
            (selectedDates && !isSoldOut) && (
              <>
                <hr />
                <h6>Rate Breakdown</h6>
                <Row>
                  <Col lg={6} className='padding-0'>
                    <ul>
                      { roomInfo.roomNights!.map(({ availableAt, roomRate }, idx) => (
                        <li key={idx}>
                          {
                            new Date(availableAt)
                              .toLocaleDateString('en-US', { day: 'numeric', month: 'long' })
                          } - ${roomRate.toFixed(2)}
                        </li>
                      ))}
                    </ul>
                  </Col>
                  <Col lg={6} className='padding-0'>
                    <strong>Taxes</strong> ${roomInfo.taxes!.toFixed(2)}<br />
                    <strong>Fee(s)</strong> ${roomInfo.fees!.toFixed(2)}<br />
                    <h6>Total <small>${roomInfo.total!.toFixed(2)}</small></h6>
                  </Col>
                </Row>
              </>
            )
          }
        </div>
      </Card>
    );
  }

  private calculateRoomCosts(roomType: RoomBlock) {
    const { selectedDates } = this.state;

    if (!selectedDates) {
      return {
        nights: -1,
      };
    }

    const roomNights = [];
    for (
      const cDate = new Date(selectedDates.checkIn);
      cDate.getTime() < new Date(selectedDates.checkOut).setHours(0, 0, 0, 0);
      cDate
    ) {
      const availability = roomType.availability.find(
        ({ availableAt }) => {
          const availableDate = new Date(availableAt).setHours(0, 0, 0, 0);
          const curDate = cDate.setHours(0, 0, 0, 0);

          return availableDate === curDate;
        });

      if (!availability || !availability.available) {
        return {
          nights: -1,
        };
      }

      roomNights.push(availability);
      cDate.setDate(cDate.getDate() + 1);
    }

    const roomTotal = roomNights.reduce((totalCost, { roomRate }) => totalCost + roomRate, 0);
    const nights = roomNights.length;

    const nightlyFee = 37.85;
    const taxRate = 0.13;

    const total = (roomTotal * (taxRate + 1))
      + (nightlyFee * nights);

    return {
      average: (total / nights).toFixed(2),
      fees: (nightlyFee * nights),
      nights,
      roomNights,
      taxRate: 0.13,
      taxes: roomTotal * taxRate,
      total,
    };
  }
}
