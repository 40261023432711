import React, { Component } from 'react';
import { RootContainer } from './components';
import { Config, Hotel } from './models';
import { ActionType } from './services/ActionTypes';
import { store } from './services/DataStore';
import { captureError } from './utils/errorHandling';

interface BookingBunAppState {
  hotels: Hotel[];
  config?: Config;
}

export class BookingBunApp extends Component<{}, BookingBunAppState> {
  public override state: BookingBunAppState = {
    hotels: [],
  };

  public override async componentDidMount() {
    api.getConfig()
    .then(async config => {
      this.transformConfig(config);
      let hotels: Hotel[] = [];
      try {
        hotels = await api.getHotels();
      } catch (e) {
        captureError(e as Error);
      }

      store.dispatch({
        config: {
          ...config,
          convention: {
            ...config.convention,
            end: new Date(config.convention.end),
            start: new Date(config.convention.start),
          },
        },
        type: ActionType.ConfigUpdate,
      });

      this.setState({
        config,
        hotels,
      });
    })
    .catch(captureError);
  }

  public override render() {
    const { config, hotels } = this.state;

    if (!config) {
      return this.renderLoading();
    }

    return <RootContainer config={config} hotels={hotels} />;
  }

  /**
   * Handles data type conversion for config received.
   */
  private transformConfig(config: Config) {
    config.convention.end = new Date(config.convention.end);
    config.convention.start = new Date(config.convention.start);
  }

  private renderLoading() {
    return (
      <div className='text-center'>
        Loading...
      </div>
    );
  }
}
