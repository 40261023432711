import { APIError, setUserContext } from '@conventioncatcorp/common-fe/dist';
import React, { Component } from 'react';
import { Route, RouteComponentProps, Router, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Card, Col, Navbar, Row } from 'reactstrap';
import { HotelContainer } from '../containers/hotels/hotelcontainer';
import { LoginContainer } from '../containers/login/logincontainer';
import { RoomContainer } from '../containers/rooms/roomcontainer';
import { Config, Hotel, User } from '../models';
import history from '../services/history';
import { Language } from './language';
import { LoginBoxComponent } from './loginbox';

interface RootContainerState {
  user?: User;
}

export class RootContainer extends Component<{ config: Config; hotels: Hotel[] }, RootContainerState> {
  public override state: RootContainerState = {};

  public override async componentDidMount() {
    await this.checkUserSession();
  }

  public formatDate(start: Date, end: Date): string {
    const month = start.toLocaleDateString('en-US', { month: 'long' });
    const endMonth = end.toLocaleDateString('en-US', { month: 'long' });
    const startStr = `${month} ${start.getDate()}`;
    const endStr = month === endMonth ? `${end.getDate()}` : `${endMonth} ${end.getDate()}`;

    return `${startStr} - ${endStr}, ${end.getFullYear()}`;
  }

  public override render() {
    const { config: { convention: { name, start, end, venue } } } = this.props;

    if (window.location.pathname === '/login') {
      return this.loadRouter();
    }

    const { user } = this.state;
    return (
      <>
        <ToastContainer
          autoClose={7000}
          newestOnTop
          draggable
          pauseOnFocusLoss
        />
        <Navbar color='dark' dark expand='md' />
        <div className='main-container'>
          <Row className='justify-content-center'>
            <Col lg={12} className='conHeader'>
              <div className='show-desktop'>
                <div className='hotel-info card-text'>
                  <img src='https://reg.goblfc.org/api/logo' />
                  <h3>{name.long}</h3>
                  <h6>{this.formatDate(start, end)}</h6>
                  <h6>{venue}</h6>
                </div>
              </div>
              <img
                style={{width: '100%'}}
                src='https://cdn.concat.app/raaar-blfc/30bb7d39-8622-482d-b0f0-476ca23e519a.png'
              />
            </Col>
            <Col lg={3}>
              <LoginBoxComponent
                config={this.props.config}
                loginBtnOnClick={() => this.initOAuthLogin()}
                updateSession={async () => this.checkUserSession()}
                user={this.state.user}
              />
              <Card className='important-banner' body>
                <div className='text-center card-text'>
                  <h5>Important</h5>
                  <Language name='importantBanner' />
                </div>
              </Card>
            </Col>
            <Col lg={7} className='padding-0'>
              {this.loadRouter(user)}
            </Col>
          </Row>
        </div>
      </>
    );
  }

  private loadRouter(user?: User) {
    const { config, hotels } = this.props;
    return (
      <Router history={history}>
        <Switch>
          <Route
            path='/login'
            render={() => <LoginContainer />}
          />
          <Route
            path='/rooms/:id'
            render={
              (props: RouteComponentProps<{ id: string }>) => {
                const hotelId = parseInt(props.match.params.id, 10);
                return (
                  <RoomContainer
                    config={config}
                    hotel={hotels.find(({ id }) => id === hotelId)!}
                    user={user}
                  />
                );
            }}
          />
          { (hotels.length !== 1) && (
            <Route
              path='/'
              render={() => (
                <HotelContainer
                  hotels={hotels}
                />
              )}
              exact
            />
          )}
          { (hotels.length === 1) && (
            <Route
              path='/'
              render={() => (
                <RoomContainer
                  config={config}
                  hotel={hotels[0]}
                  user={user}
                />
              )}
              exact
            />
          )}
        </Switch>
      </Router>
    );
  }

  private async checkUserSession() {
    try {
      const user = await api.getActiveUser();
      setUserContext(user.id);
      this.setState({
        user,
      });
    } catch (e) {
      if (!(e instanceof APIError)) {
        throw e;
      }

      const { errors: { authentication } } = e.apiResponse;

      if (authentication) {
        if (this.state.user) {
          this.setState({
            user: undefined,
          });
        }
        return;
      }

      throw e;
    }
  }

  private initOAuthLogin() {
    const popup = window.open(
      '/api/login',
      '_blank',
      'scrollbars=yes,resize=no,width=500,height=700',
    );

    if (!popup) {
      toast.error('Failed to open authentication dialog, check popup and adblocker');
      return;
    }

    let closedCheck: number;

    const listener = async (event: MessageEvent) => {
      if (event.origin !== window.location.origin) {
        return;
      }

      await this.checkUserSession();

      window.removeEventListener('message', listener, false);
      clearInterval(closedCheck);
      setTimeout(() => popup.close(), 100);
    };

    window.addEventListener('message', listener, false);
    closedCheck = setInterval(() => {
      if (popup.closed) {
        clearInterval(closedCheck);
        toast.error('Authentication cancelled!');
      }
    }, 250);
    popup.focus();
  }
}
