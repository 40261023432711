import React, { useEffect } from 'react';
import { CardBody } from 'reactstrap';
import { MaterialIcon } from '../../components';
import { useHistory, useLocation } from 'react-router';

export const LoginContainer: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    window.resizeTo(500, 330);

    if (!window.opener) {
      // TODO: Better solution
      history.push('/');
    }
  }, []);

  if (location.search) {
    const params = new URLSearchParams(location.search);
    const error = params.get('error');

    if (error) {
      return (
        <CardBody className='text-center'>
          <MaterialIcon
            name='error'
            type='danger'
            large
          />
          <h4>Login Error</h4>
          <LoginError error={error} />
          <p>
            You can now close this window.
          </p>
        </CardBody>
      );
    }
  }

  (window.opener as Window).postMessage('', window.location.origin);

  return (
    <CardBody className='text-center'>
      <MaterialIcon
        name='check_circle'
        type='success'
        large
      />
      <h4>Login Successful!</h4>
      <p>
        Hang on a second while we refresh your session...
      </p>
      <p>
        This window will close automatically.
      </p>
    </CardBody>
  );
}

const LoginError: React.FC<{ error: string }> = ({ error }) => {
  switch (error) {
    case 'underage':
      return (
        <>
          <p>
            We're sorry, but the date of birth you provided indicates you will not meet the minimum age to reserve a room before the event.
          </p>
          <p>
            If you believe this is an error, please contact the hotel team.
          </p>
        </>
      );

    case 'maintenance':
      return (
        <>
          <p>
            We're sorry, but the system is currently in maintenance mode.
          </p>
          <p>
            Please check back later!
          </p>
        </>
      );

    default:
      return (
        <>
          <p>
            We were unable to log you in as you refused the approval process. ({error})
          </p>
        </>
      );
  }
};