import { APIClient as BaseClient } from '@conventioncatcorp/common-fe/dist/APIClient';
import { init } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React from 'react';
import { render } from 'react-dom';
import { BookingBunApp } from './BookingBunApp';

import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { APIClient } from './APIClient';
import { store } from './services/DataStore';
import './style/main.scss';
import { captureError } from './utils';

declare global {
  let api: APIClient;
  interface Window {
    api: APIClient;
  }
}

const httpBase = new BaseClient();
window.api = new APIClient(new BaseClient());

init(httpBase, captureError);

render(
  <Provider store={store}>
    <BookingBunApp />
  </Provider>,
  document.getElementById('app'),
);
