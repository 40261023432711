import { Config } from '../models';
import {
  ActionType,
} from './ActionTypes';

export interface GlobalState {
  config?: Config;
}

const initialState: GlobalState = {};

export interface RootAction {
  type: ActionType;
}

export interface ConfigAction extends RootAction {
  config: Config;
}

export const rootReducer = (state: GlobalState = initialState, action: RootAction) => {
  switch (action.type) {
    case ActionType.ConfigUpdate:
      return {
        ...state,
        config: (action as ConfigAction).config,
      };

    default:
      return state;
  }
};
