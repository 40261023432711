import React, { FunctionComponent, useEffect, useState } from 'react';

interface CountdownProps {
  target: number;
  onExpiry?(): void;
}

export const Countdown: FunctionComponent<CountdownProps> = ({ target, onExpiry }) => {
  const [secondsLeft, setSecondsLeft] = useState(Math.floor((target - Date.now()) / 1000));

  useEffect(() => {
    if (secondsLeft < 0) {
      if (onExpiry) {
        onExpiry();
      }
      return;
    }

    const interval = setInterval(() => {
      setSecondsLeft(secondsLeft - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [secondsLeft]);

  function padNumber(input: number): string {
    return input.toString().padStart(2, '0');
  }

  const hours = Math.floor(secondsLeft / 3600);
  const minutes = Math.floor(secondsLeft / 60) % 60;
  const seconds = Math.floor(secondsLeft % 60);

  return (
    <>
      {hours > 0 ? `${padNumber(hours)}:` : ''}{padNumber(minutes)}:{padNumber(seconds)}
    </>
  );
};
