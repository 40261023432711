import React, { Component } from 'react';

interface ExpandableTextProps {
  text: string;
  maxLength?: number;
}

interface ExpandableTextState {
  expand?: boolean;
}

export class ExpandableText extends Component<ExpandableTextProps, ExpandableTextState> {
  public override state: ExpandableTextState = {};

  public override render() {
    const { text, maxLength } = this.props;
    const { expand } = this.state;
    const maximumLength = maxLength || 200;

    if (text.length <= maximumLength || expand) {
      return text;
    }

    return (
      <>
        {text.slice(0, maximumLength)}...&nbsp;
        <a onClick={() => this.expandText()}>
          Expand
        </a>
      </>
    );
  }

  private expandText() {
    this.setState({
      expand: true,
    });
  }
}
