import { APIError, MaterialIcon } from '@conventioncatcorp/common-fe';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardText } from 'reactstrap';
import { Config, ReleaseSchedule, User } from '../models';
import { captureError } from '../utils';
import { Countdown } from './countdown';
import { Language } from './language';

interface RoomDrawProps {
  config: Config;
  release: ReleaseSchedule;
  user?: User;
}

interface RoomDrawState {
  drawn?: boolean;
  expiresAt?: number;
  expired?: boolean;
  url?: string;
  inQueue?: boolean;
  loaded?: boolean;
  processing?: boolean;
  interval?: number;
  stripeHandler?: StripeCheckoutHandler;
  passkeyWindow: Window | null;
}

export class RoomDraw extends Component<RoomDrawProps, RoomDrawState> {
  public override state: RoomDrawState = { passkeyWindow: null };

  public override async componentDidMount(): Promise<void> {
    await this.updateQueue();

    const script = document.createElement('script');
    script.src = 'https://checkout.stripe.com/checkout.js';
    script.onload = () => {
      this.setState({
        ...this.state,
        stripeHandler: StripeCheckout.configure({
          key: this.props.config.payments.stripe,
          locale: 'auto',
          token: async ({ id }) => this.joinQueue(id),
        }),
      });
    };
    document.body.appendChild(script);
  }

  public override async componentWillReceiveProps(): Promise<void> {
    if (this.state.loaded) {
      await this.updateQueue();
    }
  }

  public override render(): JSX.Element {
    const { release } = this.props;

    return this.cardWrap(
      release.entryEnd ? this.roomDraw(release) : this.roomQueue(release),
    );
  }

  private roomQueue(release: ReleaseSchedule): JSX.Element {
    const { drawn, loaded, processing, inQueue, interval, url, expiresAt } = this.state;
    const { user } = this.props;
    const releaseStartFormat = this.formatDate(release.releaseStart);
    const releaseEndFormat = this.formatDate(release.releaseEnd);
    const expiry = release.validityPeriod ? release.validityPeriod.toString() : '';
    const hasExpiry = expiry.length > 0;
    const expired = hasExpiry && (this.state.expired || expiresAt! < Date.now());

    if (release.releaseStart.getTime() > Date.now()) {
      return (
        <Language
          name={hasExpiry ? 'nextWaveQueueExpiry' : 'nextWaveQueue'}
          attr={{ date: releaseStartFormat, expiry }}
        />
      );
    }

    if (drawn) {
      if (expired) {
        return (
          <Language
            name='waveSelectedLinkExpired'
            attr={{ expiry }}
          />
        );
      }

      if (!url) {
        return (
          <>
            <Language
              name='waveSelectedNoUrl'
            />
          </>
        );
      }

      return (
        <>
          <div>
            {expiresAt && (
              <div style={{ float: 'right' }}>
                Time Remaining
                <h3>
                  <Countdown target={expiresAt} onExpiry={async () => this.onPasskeyLinkExpiry()} />
                </h3>
              </div>
            )}
            <Language
              name={hasExpiry ? 'waveSelectedExpiry' : 'waveSelected'}
              attr={{
                date: releaseEndFormat,
                expiry,
              }}
            />
          </div>
          <br />
          <a
            href={!expiresAt ? url : '#'}
            onClick={() => expiresAt && this.openPasskeyLink()}
            className='btn btn-success btn-block'
          >
            <Language name='proceedToResLink' plain />
          </a>
        </>
      );
    }

    if (inQueue) {
      if (!interval) {
        this.setState({
          ...this.state,
          interval: setInterval(async () => this.updateQueue(), 15000),
        });
      }

      return (
        <>
          <Language
            name={hasExpiry ? 'nextWaveQueueEnteredExpiry' : 'nextWaveQueueEntered'}
            attr={{ date: releaseEndFormat, expiry }}
          />
          <br />
          <Button
            color='danger'
            onClick={async () => this.leaveQueue()}
            block
          >
            <Language name='leaveHotelQueueBtn' plain />
          </Button>
        </>
      );
    }

    if (!user) {
      return (
        <Language
          name={hasExpiry ? 'nextWaveQueueLiveExpiry' : 'nextWaveQueueLive'}
          attr={{ date: releaseStartFormat, expiry }}
        />
      );
    }

    if (release.requireReg !== 'optional') {
      const requirePaid = release.requireReg === 'requirePaid' && !user.registration.isRegistrationPaid;

      if (!user.registration.isRegistered || requirePaid) {
        return (
          <>
            <Language
              name={hasExpiry ? 'nextWaveQueueLiveExpiry' : 'nextWaveQueueLive'}
              attr={{ date: releaseEndFormat, expiry }}
            />
            <div>
              <MaterialIcon name='warning' type='warning' className='inline' />
              <Language
                name={
                  requirePaid ? 'joinRequiresPaidRegistration' : 'joinRequiresRegistration'
                }
                attr={{ type: 'queue' }}
              />
            </div>
          </>
        );
      }
    }

    return (
      <>
        <Language
          name={hasExpiry ? 'nextWaveQueueLiveNotEnteredExpiry' : 'nextWaveQueueLiveNotEntered'}
          attr={{ date: releaseEndFormat, expiry }}
        />
        { release.requireCard && (
          <>
            <br />
            <Language name='joinRequiresCard' attr={{ type: 'queue' }} />
          </>
        )}
        <br />
        { loaded && (
          <Button
            color='primary'
            onClick={async () => this.joinQueue()}
            disabled={processing}
            block
          >
            <Language name='joinHotelQueueBtn' plain />
          </Button>
        )}
        <div className='text-center'>
          <small>
            <Language name='roomEntryPolicy' plain />
          </small>
        </div>
      </>
    );
  }

  private openPasskeyLink(): void {
    const { url } = this.state;
    const passkeyWindow = window.open(url, '_blank', 'height=900,width=1500,location=no,menubar=no,resizable=no,scrollbars=yes,status=no,toolbar=no');
    window.onbeforeunload = () => passkeyWindow && passkeyWindow.close();
    this.setState({
      passkeyWindow,
    });
  }

  private async onPasskeyLinkExpiry(): Promise<void> {
    const { passkeyWindow } = this.state;

    if (passkeyWindow && !passkeyWindow.closed) {
      passkeyWindow.location.replace('about:blank');
      passkeyWindow.close();
    }

    this.setState({
      expired: true,
    });

    return;
  }

  private roomDraw(release: ReleaseSchedule): JSX.Element {
    const { drawn, loaded, processing, inQueue, url } = this.state;
    const { user } = this.props;

    const releaseStartFormat = this.formatDate(release.releaseStart);
    const releaseEndFormat = this.formatDate(release.releaseEnd);
    const entryStartFormat = this.formatDate(release.entryStart);

    const startDate = release.entryStart || release.releaseStart;
    if (startDate.getTime() > Date.now()) {
      return (
        <div id='release-not-yet-open'>
          <Language
            name='nextWaveOpening'
            attr={{
              drawDate: releaseStartFormat,
              entryDate: entryStartFormat,
            }}
          />
        </div>
      );
    }

    if (drawn) {
      return (
        <div id='release-wave-selected'>
          <Language
            name='waveSelected'
            attr={{ date: releaseEndFormat }}
          />
          <br />
          <a
            href={url}
            className='btn btn-success btn-block'
          >
            <Language name='proceedToResLink' plain />
          </a>
        </div>
      );
    }

    if (release.releaseStart.getTime() < Date.now() && !drawn) {
      if (!user) {
        return (
          <div id='release-wave-login-required'>
            <Language name='lastWaveLoginRequired' />
          </div>
        );
      }

      if (!inQueue) {
        return (
          <div id='release-prev-draw-not-entered'>
            <Language name='lastWaveNotEntered' />
          </div>
        );
      }

      return (
        <div id='release-prev-draw-unsuccessful'>
          <Language name='lastWaveUnsuccessful' />
        </div>
      );
    }

    if (release.entryEnd && release.entryEnd.getTime() < Date.now() && user) {
      return (
        <div id='release-draw-closed'>
          <Language name='nextWaveQueueClosed' />
        </div>
      );
    }

    if (inQueue) {
      return (
        <div id='release-in-draw'>
          <Language
            name='nextWaveEntered'
            attr={{ date: releaseStartFormat }}
          />
          <br />
          <Button
            color='danger'
            onClick={async () => this.leaveQueue()}
            block
          >
            <Language name='leaveHotelDrawBtn' plain />
          </Button>
        </div>
      );
    }

    if (!user) {
      return (
        <div id='release-draw-open-not-loggedin'>
          <Language
            name='nextWave'
            attr={{ date: releaseStartFormat }}
          />
          <div>
              <MaterialIcon name='info' type='info' className='inline' />
              <Language name='signInText' />
            </div>
        </div>
      );
    }

    if (release.requireReg !== 'optional') {
      const requirePaid = release.requireReg === 'requirePaid' && !user.registration.isRegistrationPaid;

      if (!user.registration.isRegistered || requirePaid) {
        return (
          <div id='release-not-registered'>
            <Language
              name='nextWave'
              attr={{ date: releaseStartFormat }}
            />
            <div>
              <MaterialIcon name='warning' type='warning' className='inline' />
              <Language
                name={
                  requirePaid ? 'joinRequiresPaidRegistration' : 'joinRequiresRegistration'
                }
                attr={{ type: 'draw' }}
              />
            </div>
          </div>
        );
      }
    }

    return (
      <div id='release-can-enter'>
        <Language
          name='nextWaveDrawing'
          attr={{ date: releaseStartFormat }}
        />
        { release.requireCard && (
          <>
            <br />
            <Language name='joinRequiresCard' attr={{ type: 'draw' }} />
          </>
        )}
        <br />
        { loaded && (
          <Button
            color='primary'
            onClick={async () => this.joinQueue()}
            disabled={processing}
            block
          >
            <Language name='joinHotelDrawBtn' plain />
          </Button>
        )}
        <div className='text-center'>
          <small>
            <Language name='roomEntryPolicy' plain />
          </small>
        </div>
      </div>
    );
  }

  private async leaveQueue(): Promise<void> {
    const { release: { id } } = this.props;

    try {
      await api.leaveQueue(id);

      await this.updateQueue();
    } catch (e) {
      // TODO
    }
  }

  private async joinQueue(token?: string): Promise<void> {
    const {
      config: {
        convention: {
          name: {
            long,
          },
        },
      },
      release: {
        id,
        requireCard,
      },
    } = this.props;

    if (requireCard && !token) {
      const { stripeHandler } = this.state;

      if (!stripeHandler) {
        return;
      }

      stripeHandler.open({
        amount: 100,
        description: 'Hotel Card Verification',
        name: long,
        zipCode: true,
      });
      return;
    }

    try {
      this.setState({
        ...this.state,
        processing: true,
      });
      await api.joinQueue(id, token);

      setTimeout(async () => this.updateQueue(), 1000);
    } catch (e) {
      this.setState({
        ...this.state,
        processing: false,
      });

      if (!(e instanceof APIError)) {
        throw e;
      }

      const { errors: { logic } } = e.apiResponse;

      if (!logic) {
        throw e;
      }

      const errors = logic.map(({ code, message }) => <li key={code as string}>{message}</li>);
      toast.error(() => (
        <>
          <p>An error occurred while processing your request:</p>
          <ul>{errors}</ul>
        </>
      ));
    }
  }

  private async updateQueue(): Promise<void> {
    const { release, user } = this.props;
    const { interval } = this.state;

    if (!user) {
      if (interval) {
        clearInterval(interval);
      }

      this.setState({
        inQueue: false,
        interval: undefined,
        loaded: true,
      });
      return;
    }

    try {
      const queueSlot = await api.getQueueInfo(release.id);
      const drawn = queueSlot && queueSlot.drawn;
      if (drawn) {
        try {
          const { expiresAt, url } = await api.getReleaseReservation(queueSlot.releaseScheduleId);

          if (interval) {
            clearInterval(interval);
          }

          this.setState({
            drawn,
            expiresAt: expiresAt && expiresAt.getTime(),
            inQueue: queueSlot && !!queueSlot.releaseScheduleId,
            interval: undefined,
            loaded: true,
            processing: false,
            url,
          });

          return;
        } catch (e) {
          if (!(e instanceof APIError)) {
            captureError(e as Error);
          } else {
            const { errors: { resource } } = e.apiResponse;

            if (!resource || resource.name !== 'Reservation') {
              captureError(e as Error);
            }
          }
        }
      }

      this.setState({
        drawn,
        inQueue: queueSlot && !!queueSlot.releaseScheduleId,
        loaded: true,
        processing: false,
        url: undefined,
      });
    } catch (e) {
      if (!(e instanceof APIError)) {
        throw e;
      }

      const { errors: { resource } } = e.apiResponse;

      if (resource) {
        if (interval) {
          clearInterval(interval);
        }

        this.setState({
          ...this.state,
          inQueue: false,
          interval: undefined,
          loaded: true,
        });
        return;
      }

      this.setState({
        ...this.state,
        loaded: true,
      });
    }
  }

  private cardWrap(children: JSX.Element): JSX.Element {
    return (
      <Card body>
        <CardText className='margin-bottom-0'>
          {children}
        </CardText>
      </Card>
    );
  }

  private formatDate(date: undefined): undefined;
  private formatDate(date: Date): string;
  private formatDate(date?: Date): string | undefined;
  private formatDate(date?: Date): string | undefined {
    if (!date) {
      return;
    }
    return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
  }
}
