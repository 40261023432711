import { APIClient as BaseAPIClient } from '@conventioncatcorp/common-fe/dist';
import { Config, Hotel, ReleaseSchedule, User } from './models';
import { QueueInfo } from './models/QueueInfo';

interface ReservationInfo {
  expiresAt?: Date;
  url: string;
}

export class APIClient {
  constructor(private readonly base: BaseAPIClient) {
  }

  public async getConfig(): Promise<Config> {
    return this.base.request<Config>({ url: '/api/config' });
  }

  public async getHotels(): Promise<Hotel[]> {
    return this.base.request<Hotel[]>({
      url: '/api/hotels',
    });
  }

  public async logout(): Promise<void> {
    await this.base.request({
      method: 'delete',
      url: '/api/login',
    });
  }

  public async joinQueue(queueId: number, token?: string): Promise<void> {
    await this.base.request({
      method: 'post',
      payload: {
        token,
      },
      url: `/api/release/${queueId}/queue`,
    });
  }

  public async leaveQueue(queueId: number): Promise<void> {
    await this.base.request({
      method: 'delete',
      url: `/api/release/${queueId}/queue`,
    });
  }

  public async getQueueInfo(queueId: number): Promise<QueueInfo> {
    return this.base.request<QueueInfo>({
      url: `/api/release/${queueId}/queue`,
    });
  }

  public async getReleaseReservation(scheduleId: number): Promise<ReservationInfo> {
    return this.base.request<ReservationInfo>({
      url: `/api/release/${scheduleId}/reservationUrl`,
    });
  }

  public async getActiveUser(): Promise<User> {
    return this.base.request<User>({
      url: '/api/login/current',
    });
  }

  public async getHotelReleaseSchedule(hotelId: number): Promise<ReleaseSchedule> {
    return this.base.request<ReleaseSchedule>({
      method: 'get',
      url: `/api/hotels/${hotelId}/release`,
    });
  }
}
