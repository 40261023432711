import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardText } from 'reactstrap';
import { Language, MaterialIcon } from '../../components';
import { Hotel } from '../../models';
import { commaFormat } from '../../utils';

export class HotelContainer extends Component<{ hotels: Hotel[] }> {
  public override render() {
    const { hotels } = this.props;
    return (
      <>
        {!hotels && (
          <Card body>
            <div className='text-center'>
              <Language name='loading' plain />
            </div>
          </Card>
        )}
        {(hotels && hotels.length === 0) && (
          <Card
            className='text-center'
            body
          >
            <Language name='noHotels' plain />
          </Card>
        )}
        {hotels && hotels.map(hotel => this.renderHotel(hotel))}
      </>
    );
  }

  private renderHotel(hotel: Hotel) {
    const hotelAddress = commaFormat([
      hotel.addressLine1,
      hotel.addressLine2,
      hotel.addressCity,
      hotel.addressState,
      hotel.addressZipcode,
    ]);
    return (
      <Card body>
        <CardText className='margin-bottom-0 room-type'>
          <h5>{hotel.name}</h5>
          <p className='icons margin-bottom-0'>
            <MaterialIcon name='info' className='inline' /> <a href='#'>More Info</a> &emsp;
            <MaterialIcon name='map' className='inline' /> <a
              target='_blank'
              href={`https://www.google.com/maps/place/${hotelAddress}`}
            >
              {hotelAddress}
            </a>
          </p>
          <p className='icons'>
            Facilities &mdash;&nbsp;
            <MaterialIcon name='casino' className='inline' />&nbsp;
            <MaterialIcon name='airport_shuttle' className='inline' />&nbsp;
            <MaterialIcon name='fitness_center' className='inline' />&nbsp;
            <MaterialIcon name='pool' className='inline' />&nbsp;
            <MaterialIcon name='local_dining' className='inline' />&nbsp;
          </p>
          <Link to={`/rooms/${hotel.id}`} className='btn btn-outline-secondary'>
            <Language name='hotelViewRooms' plain />
          </Link>
        </CardText>
      </Card>
    );
  }
}
