import { ErrorResponse as ErrorResponseBase, makeReporter } from '@conventioncatcorp/common-fe/dist/errorHandling';
import { toast } from 'react-toastify';

export const enum LogicError {
  Test = -1,
  None = 0,
  PasskeyUnknownError = 1,
}

export interface ErrorResponse extends ErrorResponseBase<LogicError> {

}

export const captureError = makeReporter({
  showError(content) {
    toast.error(() => content);
  },
  errorMessages: {
  },
});
