import MarkdownIt from 'markdown-it';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GlobalState } from '../services/RootReducer';

interface LanguageAttrs {
  [key: string]: string | undefined;
}

interface LanguageProps {
  name: string;
  attr?: LanguageAttrs;
  plain?: boolean;
}

class Language extends Component<GlobalState & LanguageProps> {
  public override render() {
    const { attr, config, name } = this.props;

    if (!config || !config.lang[name]) {
      return (
        <span>
          [missinglang:{name}]
        </span>
      );
    }

    const { lang } = config;
    return (
      <span>
        {this.parseLanguage(lang[name]!, attr)}
      </span>
    );
  }

  private parseLanguage(lang: string | string[], attr?: LanguageAttrs): JSX.Element {
    const { config } = this.props;
    const strs = Array.isArray(lang) ? [...lang] : [lang];

    for (let i = 0; i < strs.length; i++) {
      const str = strs[i]!;
      if (str.startsWith(':~')) {
        const subKey = str.substring(2);
        const subStr = config!.lang[subKey];
        strs[i] = typeof subStr === 'string' ? subStr : `[invalidsublang:${subKey}]`;
      }
    }

    const attrStr = this.replaceAttrs(strs, attr);

    return (
      <>
        {this.parseMarkdown(attrStr)}
      </>
    );
  }

  private parseMarkdown(str: string[]): JSX.Element[] {
    const { plain } = this.props;
    const md = new MarkdownIt();

    return str.map(s => {
      const element = md.renderInline(s);
      return (
        <>
          <span
            dangerouslySetInnerHTML={{
              __html: element,
            }}
          />
          {!plain && <br />}
        </>
      );
    });
  }

  private replaceAttrs(lang: string[], attr?: LanguageAttrs): string[] {
    const updatedText = lang;

    if (!attr) {
      return lang;
    }

    for (let i = 0; i < lang.length; i++) {
      for (const key of Object.keys(attr)) {
        updatedText[i] = updatedText[i]!.replace(`:${key}`, attr[key]!);
      }
    }

    return updatedText;
  }
}

function mapStateToProps(state: GlobalState) {
  const { config } = state;

  return {
    config,
  };
}

const connectedLang = (connect(mapStateToProps)(
  Language,
) as any) as React.ComponentClass<GlobalState & LanguageProps>;
export { connectedLang as Language };
