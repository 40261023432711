import React, { Component } from 'react';
import { Badge, Button, Card, CardText } from 'reactstrap';
import { Config, User } from '../models';
import { Language } from './language';

interface LoginBoxProps {
  config: Config;
  user?: User;
  loginBtnOnClick(): void;
  updateSession(): void;
}

export class LoginBoxComponent extends Component<LoginBoxProps> {
  public override render(): JSX.Element {
    if (this.props.user) {
      return this.loggedIn();
    }

    return this.notLoggedIn();
  }

  private notLoggedIn(): JSX.Element {
    const { config: { convention: { name } } } = this.props;
    return (
      <Card body>
        <div className='text-center card-text'>
          <p>
            <small>
              <Language name='signInText' />
            </small>
          </p>
          <Button
            color='primary'
            onClick={this.props.loginBtnOnClick}
            outline
            block
          >
            <Language
              name='signInBtn'
              attr={{ shortName: name.short }}
              plain
            />
          </Button>
        </div>
      </Card>
    );
  }

  private getBadge(): JSX.Element {
    const user = this.props.user!;

    if (user.admin) {
      return (
        <>
          &nbsp;
          <Badge color='danger' pill>
            Admin
          </Badge>
        </>
      );
    }

    if (user.vip) {
      return (
        <>
          &nbsp;
          <Badge color='warning' pill>
            VIP User
          </Badge>
        </>
      );
    }

    return <></>;
  }

  private loggedIn(): JSX.Element {
    const user = this.props.user!;
    return (
      <Card body>
        <CardText className='text-center'>
          <div className='avatar'>
            <img src={`https://www.gravatar.com/avatar/${user.emailHash}?d=identicon`} />
          </div>
          <h6>
            {this.renderName(user)}
            {this.getBadge()}
          </h6>
          <hr />
          <Button
            color='secondary'
            onClick={async () => this.logout()}
            block
          >
            <Language name='logoutBtn' plain />
          </Button>
        </CardText>
      </Card>
    );
  }

  private async logout(): Promise<void> {
    await api.logout();
    this.props.updateSession();
  }

  private renderName({ name }: User): string {
    if (name.preferred) {
      return name.preferred;
    }

    return `${name.first} ${name.last}`;
  }
}
